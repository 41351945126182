<script>

import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
// import DisplayCmp from "./components/DisplayCmp";
export default {
  props: { reloadOrders: {}
  },
  components: { Dataset, DatasetItem, /* DatasetInfo,*/DatasetPager, /* DatasetSearch,*/ DatasetShow  },
  data() {
    return {
      tableData: [],
      title: "Ordres de maintenance",
      orders: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      active : false,
      selectedRows:[],
      cols: [
         {
          name: "Référence ",
          field: "ref",
          sort: ''
        },
        {
          name: "Créer Par",
          field: "createdBy",
          sort: ''
        },
        {
          name: "Source",
          field: "source",
          sort: ''
        },
        {
          name: "Créer le",
          field: "createdAt",
          sort: ''
        },
        {
          name: "Status",
          field: "status",
          sort: ''
        },
        {
          name: "Etat d'exécution",
          field: "executionStatus",
          sort: ''
        },
      ],
      selectedIndex:-1,
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  }, 
  watch : {
    selectedRows(){
      if(this.selectedRows.length && undefined != this.selectedRows[0]){
        this.$emit('selectedOrders',this.selectedRows)
      }else{
        this.$emit('selectedOrders',[])
      }
    },
    
    reloadOrders:{
        handler(val){
          if(val){
            this.refreshMaintenanceOrdersTable();
          }
        }
      }
  },
  mounted() {
    this.totalRows = this.tableData.length;
    this.refreshMaintenanceOrdersTable();
  },
  methods: {
    
    refreshMaintenanceOrdersTable() {
      let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
      })
      this.$http
        .post("/ops/maintenance_orders/list")
        .then((res) => {
          this.orders = res.data.original.list;
          loader.hide();
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
  },

};
</script>

<template>
<div class="row">
    <div class="col-12">
      <div class="accordion mb-2" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
              <div class="row">
                <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des requets (Maintenance)</div>
                <div class="col text-right">
                  <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                  <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                </div>
              </div>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
            <b-card-body class="shadow-lg">
            <div class="table-responsive mb-0 shadow">
              <dataset v-slot="{ ds }" :ds-data="orders" >
                <div class="row">
                  <div class="col-md-6 mb-2 mb-md-0">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="">
                      <table class="table table-hover d-md-table">
                        <thead>
                          <tr>
                            <th v-for="(th) in cols" :key="th.field">
                              {{ th.name }} 
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                              <td>{{ row.ref }}</td>
                              <td>{{ row.createdBy }}</td>
                              <td>{{ row.source }}</td>
                              <td><span v-html="row.createdAt"></span></td>
                              <td><span v-html="row.status"></span></td>
                              <td><span v-html="row.executionStatus"></span></td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                  <dataset-show :ds-show-entries="5" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
 </div>
</template>