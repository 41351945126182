<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import maintenanceOrdersTable from "./components/maintenanceOrdersTable";
import Swal from "sweetalert2";
export default {
  page: {
    title: "Ordres de maintenance",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, maintenanceOrdersTable },
  data() {
    return {
      title: "Maintenance",
      
      items: [
        {
          text: "Catalogue des Services",
          to: {name:"service_catalog"},
        },
        {
          text: "Maintenance",
          to: {name:"service_catalog.maintenance.index"},
        },
        {
          text: "Suive de maintenance",
          active: true,
        },
      ],
      uuid: "",
      maintenanceOrderDataProps : [],
      maintenanceOrderData: {
        id: 0,
        uuid: "",
        ref: "",
        description: "",
        createdBy: "",
        createdAt: "",
        maintenanceRequestData: [],
        start_date: "",
        planned_end_date: "",
        planned_end_times: "",
        status: "",
        hasAssignedEmployees: "",
        execution_type: "",
        supplier_id: null,
        assignedEmployees: [],
        supplierHasTerminatedTheTask: "",
        employee_assignment_comments:"",
        evaluate_comments :"",
        executionStatus:"",
        statusPlain :"",
        assignedProducts:"",
        extension : []
      },
      selectedRows: null,
      reloadOrders:undefined,
      supplier_id : null,
      realoadSingleMaintenanceOrderData : false
    }
  },
  mounted() {
  },
  watch:{
    realoadSingleMaintenanceOrderData: function (val){
       if(val)
        this.fetchSingleMaintenanceOrderData();
        this.reloadOrders = true;
    }
  },
  methods: {
    selectedOrders(selectedOrders){
      this.clearForms
      if(selectedOrders.length> 0 &&  undefined != selectedOrders[0]){
        this.uuid = selectedOrders[0].uuid;
        this.supplier_id = selectedOrders[0].supplier_id;
        this.fetchSingleMaintenanceOrderData();
      }
    },
    clearForms(){
      this.kitchenForm   = this.kitchenFormRest
    },
    fetchSingleMaintenanceOrderData() {
      var _this = this; 
      let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
      })
      this.$http
        .post("/ops/maintenance_orders/getSingleOrderData/" + this.uuid)

        .then((res) => {
          var status = res.data.original.status;
          var mo = res.data.original.mo;
          switch (status) {
            case 200:

              this.maintenanceOrderData.id = "";
              this.realoadSingleMaintenanceOrderData = false;
              _this.maintenanceOrderData.id = mo.id;
              _this.maintenanceOrderData.ref = mo.ref;
              _this.maintenanceOrderData.assignedEmployees = mo.assignedEmployees;
              _this.maintenanceOrderData.description = mo.description;
              _this.maintenanceOrderData.createdBy = mo.createdBy;
              _this.maintenanceOrderData.createdAt = mo.createdAt;
              if(mo.maintenanceRequestData != null)
              _this.maintenanceOrderData.maintenanceRequestData =
                mo.maintenanceRequestData;
              _this.maintenanceOrderData.start_date = mo.start_date;
              _this.maintenanceOrderData.planned_end_date = mo.planned_end_date;
              _this.maintenanceOrderData.planned_end_times =
                mo.planned_end_times;
              _this.maintenanceOrderData.status = mo.status;
              _this.maintenanceOrderData.hasAssignedEmployees =
                mo.hasAssignedEmployees;
              _this.maintenanceOrderData.execution_type = mo.execution_type;
              _this.maintenanceOrderData.supplier_id = mo.supplier_id;
              _this.maintenanceOrderData.supplierHasTerminatedTheTask = mo.supplierHasTerminatedTheTask;
              _this.maintenanceOrderData.employee_assignment_comments = mo.employee_assignment_comments;
              _this.maintenanceOrderData.statusPlain = mo.statusPlain;
              _this.maintenanceOrderData.evaluate_comments = mo.evaluate_comments;
              _this.maintenanceOrderData.assignedProducts = mo.assignedProducts;
              _this.maintenanceOrderData.executionStatus = mo.executionStatus;
              this.maintenanceOrderDataProps = this.maintenanceOrderData;
              this.maintenanceOrderData.extension = mo.extension
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
          loader.hide();
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },

    changeExtensionStatus(status,id){
        this.$http
        .post("/ops/maintenance_orders/extensionRequestApprove", {status : status , id : id  })

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.fetchSingleMaintenanceOrderData();
              this.$toast.success("l'operation a ete effectuée avec succes");
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              
              break;
          }
        })
        .catch(() => {
          
        })
        .finally(() => {});
      }
  },
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <maintenance-orders-table :reloadOrders="reloadOrders" @selectedOrders="selectedOrders" @reloadOrdersDone="reloadOrders = $event"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
            </div>
            <h4 class="card-title">
              {{ maintenanceOrderData.ref }}
            </h4>
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Détails</span>
                </template>
                <div class="row">
                  <div class="col-xl-12">

                    <div class="row">
                      <div class="col-md-6">
                        <div class="table-responsive mb-0">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th>Demandeur :</th>
                                <td>
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .requestedBy
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Type de maintenance :</th>
                                <td>
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .type
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Lieu de maintenance :</th>
                                <td>
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .location
                                  }}
                                  /
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .locationRec
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Urgence :</th>
                                <td>
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .urgency
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Source :</th>

                                <td>{{maintenanceOrderData.maintenanceRequestData.ref}}
                                </td>
                              </tr>
                                    
                              <tr v-if="maintenanceOrderData.maintenanceRequestData.ov_file">
                                <th>Attachement :</th>
                                <td><a :href="maintenanceOrderData.maintenanceRequestData.ov_file.fullPath" target="_blank">{{maintenanceOrderData.maintenanceRequestData.ov_file.name}} <i class="fas fa-download"></i></a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="table-responsive mb-0">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th>Date de création :</th>
                                <td>
                                  {{ maintenanceOrderData.createdAt }}
                                </td>
                              </tr>
                              <tr>
                                <th>Ordre créer par :</th>
                                <td>
                                  {{ maintenanceOrderData.createdBy }}
                                </td>
                              </tr>
                              <tr>
                                <th>Date début d'intervention :</th>
                                <td>
                                  {{ maintenanceOrderData.start_date }}
                                </td>
                              </tr>
                              <tr>
                                <th>Date fin d'intervention prévue :</th>
                                <td>
                                  {{ maintenanceOrderData.planned_end_date }}
                                  {{ maintenanceOrderData.planned_end_times }}
                                </td>
                              </tr>
                              <tr>
                                <th>Etat :</th>
                                <td>
                                  <span
                                    v-html="maintenanceOrderData.status"
                                  ></span>
                                </td>
                              </tr>

                              <tr>
                                <th>Etat d'exécution :</th>
                                <td>
                                  <span
                                    v-html="
                                      maintenanceOrderData.executionStatus
                                    "
                                  ></span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h4 class="card-title mb-4">Détails</h4>
                        <p lass="text-muted mb-4">
                          {{ maintenanceOrderData.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab v-show='maintenanceOrderData.execution_type == "supplier" && maintenanceOrderData.extension'>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Prolongations</span>
                </template>

                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table mb-0 table-bordered">
                              <thead>
                                  <tr>
                                      <th>début</th>
                                      <th>fin</th>
                                      <th>Status </th>
                                      <th>Commentaires </th>
                                      <th>Action </th>
                                  </tr>
                              </thead>

                              <tbody>
                                  <tr v-for="extension in maintenanceOrderData.extension" v-bind:key="extension.id">
                                      <td>{{ extension.startDate }}</td>
                                      <td>{{ extension.endDate }} {{ extension.endTime }}</td>
                                      <td v-html="extension.status"></td>
                                      <td v-html="extension.comments"></td>
                                      <td >
                                        <span v-if="extension.statusPlain == 'draft'">
                                        <button type="button" @click="changeExtensionStatus('approved',extension.id)" class="btn btn-primary" >
                                          <i class="fas fa-check  text-white "></i> 
                                        </button>
                                        <button type="button" @click="changeExtensionStatus('rejected',extension.id)" class="btn btn-danger" >
                                          <i class="fas fa-times  text-white "></i>
                                        </button>
                                        </span>
                                      </td>
                                      
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
                </div>
              </b-tab>
              <b-tab >
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Intervenants</span>
                </template>

                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table mb-0 table-bordered">
                              <thead>
                                  <tr>
                                      <th>Matricule</th>
                                      <th>Nom</th>
                                      <th>Prénom</th>
                                      <th>Fonction</th>
                                  </tr>
                              </thead>

                              <tbody>
                                  <tr v-for="employee in maintenanceOrderData.assignedEmployees" v-bind:key="employee.id">
                                      <td>{{ employee.employee_number }}</td>
                                      <td>{{ employee.first_name }}</td>
                                      <td>{{ employee.last_name }}</td>
                                      <td>{{ employee.postTitle }}</td>
                                      
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Evaluation</span>
                </template>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table mb-0 table-bordered">
                              <thead>
                                  <tr>
                                      <th>Code Barre</th>
                                      <th>Désignation</th>
                                      <th>Quantité</th>
                                  </tr>
                              </thead>

                              <tbody>
                                  <tr v-for="product in maintenanceOrderData.assignedProducts" v-bind:key="product.id">
                                      <td>{{ product.codebare }}</td>
                                      <td>{{ product.name }}</td>
                                      <td>{{ product.qty }}</td>
                                      
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Rapport</span>
                </template>
                  <div class="row">
                      <span v-html="maintenanceOrderData.evaluate_comments"></span>
                  </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>